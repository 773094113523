<template>
    <header class="topbar-container">
        <ModalTurboSearch
            v-if="modalTurboSearchVisibility && (userCan('read clients') || userCan('read employees') || userCan('read keys'))"
            :modalVisible="modalTurboSearch"
            @close="closeSearchModal"
        />

        <h1>{{ title }}</h1>

        <div v-if="newVersionAvailable" class="py-2 px-5 bg-green-400 border border-green-500 text-white rounded-lg">
            New system version available, please refresh the page to update.
        </div>

        <div class="right-side">
            <!-- <MessageIcon /> -->

            <!-- <NotificationIcon /> -->

            <!-- <TurboSearchIcon
                v-if="userCan('read clients') || userCan('read employees') || userCan('read keys')"
                @clicked="openModalTurboSearch"
            /> -->

            <!-- <CrispChatIcon /> -->

            <p class="btn-logout" @click="logout">
                <fa-icon :icon="['far', 'power-off']" /> {{ $t('topbar.logout') }}
            </p>
        </div>
    </header>
</template>
<script>
export default {
    components: {
        NotificationIcon: () => import('./components/NotificationIcon.vue'),
        CrispChatIcon:    () => import('./components/CrispChatIcon.vue'),
        TurboSearchIcon:  () => import('./components/TurboSearchIcon.vue'),
        ModalTurboSearch: () => import('./components/ModalTurboSearch.vue'),
        MessageIcon:      () => import('./components/MessageIcon.vue'),
    },

    data() {
        return {
            newVersionAvailable:        false,
            modalTurboSearchVisibility: false,
            modalTurboSearch:           false,
        };
    },

    computed: {
        environmentSetting() {
            return process.env.VUE_APP_ENV;
        },

        title() {
            return this.$store.state.title;
        },
    },

    watch: {
        title(value) {
            document.title = `Workflows.se - ${value}`;
        },
    },

    created() {
        window.addEventListener('keydown', this.keyDown);
    },

    methods: {
        keyDown(e) {
            if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                this.openModalTurboSearch();
            }
        },

        openModalTurboSearch() {
            this.modalTurboSearchVisibility = true;
            this.modalTurboSearch = true;
        },

        closeSearchModal() {
            this.modalTurboSearchVisibility = false;
            this.modalTurboSearch = false;
        },

        logout() {
            window.Cookies.remove('api_token');
            window.Cookies.remove('user_uuid');
            window.Cookies.remove('user_email');
            this.$router.push('/login');
        },
    },
};
</script>
<style scoped>
    .topbar-container {
        height: 56px;
        top: 0;
        margin-left: -15px;
        padding-right: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        box-shadow: 0px 1px 2px 1px #00000017;
    }

    .btn-logout {
        transition: 250ms ease all;
    }

    .btn-logout:hover {
        cursor: pointer;
        text-shadow: 0 0 1px #000000de;
    }

    h1 {
        font-size: 21px;
        font-weight: 300;
        color: #333;
    }

    .right-side {
        display: flex;
        align-items: center;
    }

    .alert {
        margin-right: 20px;
        margin-left: 2px;
    }

    .alert div {
        font-size: 18px;
        color: #535353;
    }
</style>
<style>
    .topBar .el-badge__content {
        line-height: 15px;
        box-sizing: unset;
        right: 7px;
    }
</style>
